import React from 'react';
import { Backlinks } from 'views/backlinks';
import { renderOptions } from 'views/blog-post/utils';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/backlinks.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const BacklinksPage = ({ location, data }) => {
    const document = JSON.parse(
        data?.allContentfulBacklinksList?.edges[0]?.node?.list?.raw,
    );
    const content = document
        ? documentToReactComponents(document, renderOptions([], true))
        : null;

    return (
        <Layout location={location} messages={messages}>
            {content && <Backlinks>{content}</Backlinks>}
        </Layout>
    );
};

BacklinksPage.propTypes = {
    location: object.isRequired,
    data: object.isRequired,
};

export default BacklinksPage;

export const Head = () => <SEO tags={TAGS.BACKLINKS} />;

export const pageQuery = graphql`
    query {
        allContentfulBacklinksList(
            filter: { title: { eq: "Mobile Reality's backlinks" } }
        ) {
            edges {
                node {
                    id
                    list {
                        raw
                    }
                }
            }
        }
    }
`;
