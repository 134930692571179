import React from 'react';
import styled, { css } from 'styled-components';
import { CopyLinkButton } from 'components/copy-link-button';
import { array, bool, func, node, object, oneOfType, string } from 'prop-types';

const SHeaderThird = styled.h3`
    font-size: 1.375rem;
    ${({ isCustom }) =>
        isCustom &&
        `
        letter-spacing: 2.4px;
    `}
    ${({ hasCopyButton }) =>
        hasCopyButton &&
        css`
            &:hover .copy-link-button {
                opacity: 1;
            }
        `}
`;

const SCopyLinkButton = styled(CopyLinkButton)`
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
`;

export const HeaderThird = ({
    children,
    className,
    isCustom,
    id,
    handleCopyUrl,
}) => {
    const hasCopyButton = id && handleCopyUrl;

    return (
        <SHeaderThird
            className={className}
            isCustom={isCustom}
            id={id}
            hasCopyButton={hasCopyButton}
        >
            {children}
            {hasCopyButton && (
                <SCopyLinkButton
                    className="copy-link-button"
                    onClick={handleCopyUrl}
                />
            )}
        </SHeaderThird>
    );
};

HeaderThird.propTypes = {
    children: oneOfType([array, object, node]).isRequired,
    className: string,
    isCustom: bool,
    id: string,
    handleCopyUrl: func,
};

HeaderThird.defaultProps = {
    className: '',
    isCustom: false,
    id: null,
};
