import { HUB_TAGS, HUB_URLS } from 'views/single-blog-genre/constants';
import { LEGACY_BLOG_POSTS_DATE } from 'constants/blog-post';
import { PATHS } from 'constants/paths';

export const formatFaqData = (data) => {
    if (!data) {
        return { title: '', data: [] };
    }

    const formattedQa = data.qa
        ? data.qa.map((item) => {
              const question = item.question.internal.content;
              const answer = JSON.parse(item.answer.raw);

              return { question, answer };
          })
        : [];

    return { title: data.title, data: formattedQa };
};

export const getBlogPostHubs = (blogPost) => {
    const tags = blogPost.metadata?.tags?.map((tag) => tag.name) || [];

    const hubs = [];

    const entries = Object.entries(HUB_TAGS);
    for (const [key, list] of entries) {
        const isMatch = tags.some((tag) => list.includes(tag));
        if (isMatch) {
            hubs.push(key);
        }
    }

    return hubs;
};

export const getBlogPostUrl = (blogPost, hub, isPressRelease) => {
    if (isPressRelease) {
        return `/${PATHS.PRESS_RELEASE}/${blogPost.slug}`;
    }

    const hubs = getBlogPostHubs(blogPost);
    const resolvedHub = hub || HUB_URLS[hubs[0]];

    const isLegacyPostRoute =
        new Date(blogPost.createdAt || blogPost.date) <= LEGACY_BLOG_POSTS_DATE;

    return `/${PATHS.BLOG}${isLegacyPostRoute ? '' : `/${resolvedHub}`}/${
        blogPost.slug
    }`;
};
