import React from 'react';
import { array, node, object, oneOfType, string } from 'prop-types';

export const HeaderFourth = ({ children, className }) => {
    return <h4 className={className}>{children}</h4>;
};

HeaderFourth.propTypes = {
    children: oneOfType([array, object, node]).isRequired,
    className: string,
};

HeaderFourth.defaultProps = {
    className: '',
};
