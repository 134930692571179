import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { object, string } from 'prop-types';

const propTypes = {
    childImageSharp: object.isRequired,
    className: string,
    alt: string,
};

const defaultProps = {
    className: '',
    alt: '',
};

const SImageWrapper = styled.div`
    width: 100%;
`;

const _NonStretchedImage = (props) => {
    const { className, alt, childImageSharp } = props;

    const style = {
        maxWidth: childImageSharp.gatsbyImageData.presentationWidth,
        margin: '0 auto',
    };

    return (
        <SImageWrapper className={className}>
            <GatsbyImage
                className={className}
                image={childImageSharp.gatsbyImageData}
                alt={alt}
                style={style}
            />
        </SImageWrapper>
    );
};

_NonStretchedImage.propTypes = propTypes;
_NonStretchedImage.defaultProps = defaultProps;
export const NonStretchedImage = _NonStretchedImage;
