import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { Specification } from 'modules/projects/components/specification';
import { node } from 'prop-types';

const SSubContainer = styled.div`
    margin-top: 6.25rem;
    word-wrap: break-word;

    & > * {
        margin-bottom: 1rem;
    }

    > ul {
        margin-left: 2rem;
        > li {
            margin-bottom: 1rem;
        }
    }

    h2 {
        margin-top: 0;

        :not(:first-child) {
            margin-top: 8rem;
        }
    }

    table {
        border-collapse: collapse;
    }

    td,
    th {
        border: 1px solid var(--black-color);
        padding: 1rem;
    }

    hr {
        display: none;
    }
`;

export const Backlinks = ({ children }) => {
    return (
        <Container>
            <Specification
                texts={{
                    topSubTitle: 'backlinks.header',
                    title: 'backlinks.headline',
                }}
                isBoxStyle
            />
            <SSubContainer>{children}</SSubContainer>
        </Container>
    );
};

Backlinks.propTypes = {
    children: node.isRequired,
};
