import React from 'react';
import styled, { css } from 'styled-components';
import { bool, node, oneOfType, string } from 'prop-types';

const STitle = styled.h1`
    font-weight: 600;
    color: var(--black-text-color);
    line-height: 1;
    font-size: clamp(0.75rem, 10vw, 3.625rem);
    letter-spacing: 0.5px;
    margin: 0.5rem;
    text-align: ${({ align }) => align};

    ${({ withAfterSelector }) =>
        withAfterSelector &&
        css`
            &:after {
                content: '';
                height: 3px;
                width: 2.5rem;
                display: block;
                background: var(--primary-color);
                margin: ${({ underlineCenter }) =>
                    underlineCenter
                        ? `1.3rem auto`
                        : '1.3rem auto auto 0.2rem'};
            }
        `}
`;

export const MainTitle = ({
    children,
    className,
    underlineCenter,
    align,
    withAfterSelector,
}) => {
    return (
        <STitle
            className={className}
            underlineCenter={underlineCenter}
            align={align}
            withAfterSelector={withAfterSelector}
        >
            {children}
        </STitle>
    );
};

MainTitle.propTypes = {
    children: oneOfType([string, node]).isRequired,
    className: string,
    align: string,
    underlineCenter: bool,
};

MainTitle.defaultProps = {
    className: '',
    align: 'center',
    underlineCenter: true,
    withAfterSelector: true,
};
