import React from 'react';
import styled from 'styled-components';
import { NonStretchedImage } from 'components/non-stretched-image';
import { CONSTANTS } from 'constants/styles/constants';
import { object } from 'prop-types';

const SPageBackground = styled(NonStretchedImage)`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 769px;
    width: 100%;
    max-width: var(--section-max-width);
    z-index: ${CONSTANTS.ZINDEX.LOWEST};
`;

export const PageBackground = ({ childImageSharp, alt }) => (
    <SPageBackground childImageSharp={childImageSharp} alt={alt} />
);

PageBackground.propTypes = {
    childImageSharp: object.isRequired,
};
