import { graphql } from 'gatsby';

export const postQuery = graphql`
    fragment BlogPostFragment on ContentfulBlogPost {
        id
        title
        genre
        genreExpertise
        genreTechnology
        genreTechnologies
        shortDescription {
            internal {
                content
            }
        }
        createdAt
        date
        updatedAt
        image {
            gatsbyImageData(width: 772, height: 541, quality: 100)
        }
        thumbnail {
            gatsbyImageData(width: 600, height: 420, quality: 100)
        }
        slug
        altThumbnailDescription
        metadata {
            tags {
                name
            }
        }
        multipleAuthors {
            name
            jobTitle
            picture {
                gatsbyImageData(width: 200, quality: 100)
            }
        }
    }
`;

export const faqFragment = graphql`
    fragment FaqFragment on ContentfulFaq {
        title
        qa {
            answer {
                raw
            }
            question {
                internal {
                    content
                }
            }
        }
    }
`;

// when changing change also in gatsby-node.js
export const HUB_TAGS = {
    FINTECH: ['fintech'],
    PROPTECH: ['proptech'],
    BLOCKCHAIN: ['blockchain-web3'],
    HR: ['eb-hr-resources'],
    JAVASCRIPT: [
        'react-js',
        'vue-js',
        'node-js',
        'react-native',
        'mobile',
        'backend',
        'frontend',
        'software-architecture',
    ],
    SOFTWARE_BUSINESS: [
        'software-development-business',
        'business-intelligence',
        'data-science-ML',
        'gen-ai',
        'entertainment-streaming',
        'cloud-devops',
        'software-architecture',
        'qa-software-testing',
        'other',
        'ux-ui-design',
        'healthtech',
    ],
};

export const HUB_TAGS_DISPLAY = {
    FINTECH: 'list.fintech.title',
    PROPTECH: 'list.proptech.title',
    BLOCKCHAIN: 'list.blockchain.title',
    HR: 'list.hr.title',
    JAVASCRIPT: 'list.javascript.title',
    SOFTWARE_BUSINESS: 'list.software-business.title',
};

// when changing change also in gatsby-node.js
export const HUB_URLS = {
    FINTECH: 'fintech',
    PROPTECH: 'proptech',
    BLOCKCHAIN: 'blockchain',
    HR: 'hr',
    JAVASCRIPT: 'javascript',
    SOFTWARE_BUSINESS: 'business',
};
